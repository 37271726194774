<template>
  <div class="member-card-coupou">
    <el-form :inline="true">
      <el-form-item label="门店">
        <!-- <el-select clearable v-model="filterSearch.storeCode" filterable>
          <el-option v-for="item in storeList" :label="item.label" :value="item.value" :key="item.value"></el-option>
        </el-select> -->
        <el-select-v2
          v-model="filterSearch.storeCode"
          :options="storeList"
          filterable
          placeholder="请选择门店"
          clearable
        />
      </el-form-item>
      <el-form-item label="投放渠道 ">
        <el-select clearable v-model="filterSearch.channel">
          <el-option v-for="item in types" :label="item.label" :key="item.code" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="selectInfoPage">查询</el-button>
        <el-button @click="resetFilter">重置</el-button>
        <el-button @click="addMemberCardCoupou" v-if="authMenus.add">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData">
      <el-table-column align="center" prop="storedAmount" show-overflow-tooltip label="充值金额"></el-table-column>
      <el-table-column align="center" prop="giftAmount" show-overflow-tooltip label="赠送金额"></el-table-column>
      <el-table-column align="center" prop="channel" show-overflow-tooltip label="投放渠道"></el-table-column>
      <el-table-column align="center" show-overflow-tooltip label="开始时间">
        <template #default="{row}">
          {{ row.beginTime ? row.beginTime : '不限' }}
        </template>
      </el-table-column>
      <el-table-column align="center" show-overflow-tooltip label="结束时间">
        <template #default="{row}">
          {{ row.endTime ? row.endTime : '不限' }}
        </template>
      </el-table-column>
      <el-table-column align="center" show-overflow-tooltip label="赠金当日不可用">
        <template #default="scope">
          是
        </template>
      </el-table-column>
      <el-table-column align="center" show-overflow-tooltip label="赠送优惠券数量" prop="couponsCount">
      </el-table-column>
      <el-table-column align="center" show-overflow-tooltip label="适用门店">
        <template #default="scope">
          <span v-if="scope.row.isFixedStore === 0">全部门店</span>
          <span v-else style="color:rgb(0, 217, 255);cursor: pointer;" @click="showStore(scope.row)">指定门店</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template v-slot="{ row }">
          <el-button type="text" @click="updateMemberCardCoupou(row)" v-if="authMenus.update">编辑</el-button>
          <el-button type="text" @click="deleteMemberCardCoupou(row)" v-if="authMenus.delete">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="pageParams.currentPage"
      v-model:page-size="pageParams.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageParams.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
  <addOrUpdate
    v-if="dialogVisible"
    @handleCloseDialog="handleCloseOrOpenDialog"
    :updateRowMsg="updateRowMsg"
    :authMenus="authMenus"
  ></addOrUpdate>
</template>

<script setup>
import commonApi from '@/api'
import { ref, onBeforeMount, reactive } from 'vue'
import addOrUpdate from './add-or-update.vue'
import API from '../../../service/index'
import { ElMessage, ElMessageBox, ElNotification } from 'element-plus'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'

const props = defineProps({
  auths: {},
})

//分页参数
const pageParams = reactive({
  currentPage: 1,
  pageSize: 10,
  total: 0,
})

/**
 * 展示指定门店信息
 */
const showStore = data => {
  ElNotification({
    title: '指定的门店',
    message: `当前包含的门店有：${data.storeNames ? data.storeNames : '苏州店，厦门店，上海店'}`,
    duration: 0,
  })
}

//投放渠道
const types = [
  {
    code: 'POS',
    label: 'POS',
  },
  {
    code: '小程序',
    label: '小程序',
  },
]

const dialogVisible = ref(false) //弹出框
const updateRowMsg = ref({}) //需要操作的某一行数据
/**
 * 打开或者关闭弹出框
 * @param {boolean} val
 */
const handleCloseOrOpenDialog = val => {
  dialogVisible.value = val
  if (!val) {
    selectInfoPage()
  }
}

const storeList = ref([])
/**
 * 获取门店
 */
const getStoreList = async () => {
  let res = await commonApi.getNewShop({})
  //门店接口
  storeList.value = res.data.map(item => {
    return { value: item.code, label: item.code + '--' + item.name }
  })

  // filterSearch.storeCode = res.data[0].code
}

/**
 * 重制
 */
const resetFilter = () => {
  filterSearch.storeCode = ''
  filterSearch.channel = ''
  selectInfoPage()
}

//搜索条件
const filterSearch = reactive({
  storeCode: '',
  channel: '',
})

//表单数据
const tableData = ref([])
/**
 * 获取表单数据
 */
const selectInfoPage = async () => {
  if (authMenus.value.query) {
    let res = await API.getPriceLsit({
      ...filterSearch,
      ...pageParams,
      type: 'COMMON',
    })
    if (res.code === '200') {
      tableData.value = res.data.records || []
      pageParams.total = res.data.totalRecords
    }
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}

const authMenus = ref([])
const route = useRoute()
onBeforeMount(async () => {
  route.meta.msg = props.auths
  operationAuth(route, authMenus.value)
  await getStoreList()
  selectInfoPage()
})

/**
 *新增优惠卷
 */
const addMemberCardCoupou = () => {
  updateRowMsg.value = { channel: ['POS', '小程序'], isFixedStore: 1, fixedStores: [], coupons: [] }
  handleCloseOrOpenDialog(true)
}

/**
 * 修改某一行数据
 * @param {object} row
 */
const updateMemberCardCoupou = async row => {
  let { data } = await API.getPriceDetail({ storedCode: row.code })
  updateRowMsg.value = {
    ...data,
    channel: data.channel.split(','),
    time: [data.beginTime, data.endTime],
    fixedStores: data.isFixedStore === 0 ? [] : data.fixedStores,
  }

  handleCloseOrOpenDialog(true)
}

/**
 * 删除某一行数据
 * @param {object} row
 */
const deleteMemberCardCoupou = row => {
  ElMessageBox.confirm('确认删除当条充值套餐吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    let res = await API.priceDeleteStored({ storedCode: row.code })
    if (res.code === '200') {
      ElMessage.success('删除成功')
    } else {
      ElMessage.error(res.msg)
    }
    selectInfoPage()
  })
}

//分页
const handleSizeChange = val => {
  pageParams.pageSize = val
  selectInfoPage()
}
const handleCurrentChange = val => {
  pageParams.currentPage = val
  selectInfoPage()
}
</script>

<style scoped lang="scss">
.member-card-coupou {
  padding: 20px 10px;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
::v-deep .el-select-v2__placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
